/*
 * CUSTOM SCSS
 *
 * Should you need to customize the admin, this is the file to do it!
 * Also, if you'd like, you can change the supporting color of the admin
 * by replacing the hex color code of $support-color-admin variable
 */

// Disable warnings in terminal
$show-warnings: false;

/* Support color of the admin */
$support-color-admin: #2199e8;

/* All general style of the admin */
@import '../../../../vendor/click/clickadmin/src/resources/assets/sass/login/app';


/* Place all your custom styles below */
